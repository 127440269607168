<template>
  <body>
    <!-- preloader -->
    <Loader> </Loader>
    <!-- preloader -->
    <div class="frame">
      <div class="site-wrapper overflow-hidden">
        <!-- <Front-Header> </Front-Header> -->
        <div class="patners-listing-page">
          <div class="container">
            <h1>Business Directory</h1>

            <!-- <nav class="AlphabetNav">
              <a v-for="al in alphabate" :key="al" v-on:click="handleSelectItem(al.alfa)">{{ al.alfa }}</a>
            </nav> -->
            <form class="search_form" v-on:submit.prevent="submit">
              <a href="#" class="hide_form"><i class="fa fa-times"></i></a>
              <!-- <input type="text" v-model="searchQuery" @keyup="onKeyup" placeholder="Search by Company name"
                class="form-control" /> -->
                <input type="text" v-model="searchQuery" placeholder="Search by Country name"
                class="form-control" />
              <button type="submit">
                <img src="@/assets/front/image/svg/search.svg" />
              </button>
            </form>
            <div class="listing-wrapper">
              <div class="listing-item" v-for="partners in displayedPosts" :key="partners._id">

                <h4 class="listing-item-name">{{ partners.title }}</h4>
                <img class="listing-item-logo" :src="partners.companylogo" />
                <div class="listing-item-details">
                  <p class="listing-item-disc">
                    {{ partners.about }}
                  </p>

                  <router-link :to="{ path: 'directory/' + partners.slugurl }" class="listing-item-ncta">Read More<i
                      class="fa fa-chevron-right" aria-hidden="true"></i></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Front-Footer> </Front-Footer>
      </div>
    </div>
  </body>
</template>
<script>
import Header from "./Front-Header";
import Footer from "./Front-Footer";
import Loader from './Loader';
import { db } from "@/main";
import VueMeta from 'vue-meta';
import Vue from 'vue';
Vue.use(VueMeta)
export default {
  components: {
    "Front-Header": Header,
    "Front-Footer": Footer,
    'Loader': Loader,
  },
  data() {
    return {
      partner: [], slugurl: '', about: '', companylogo: '', companyheroimage: '', page: 1,
      perPage: 2,
      search: '',
      searchQuery: null,
      pages: [],
      alphabate: [
        { alfa: 'A' },
        { alfa: 'B' },
        { alfa: 'C' },
        { alfa: 'D' },
        { alfa: 'E' },
        { alfa: 'F' },
        { alfa: 'G' },
        { alfa: 'H' },
        { alfa: 'I' },
        { alfa: 'J' },
        { alfa: 'K' },
        { alfa: 'L' },
        { alfa: 'M' },
        { alfa: 'N' },
        { alfa: 'O' },
        { alfa: 'P' },
        { alfa: 'Q' },
        { alfa: 'R' },
        { alfa: 'S' },
        { alfa: 'T' },
        { alfa: 'U' },
        { alfa: 'V' },
        { alfa: 'W' },
        { alfa: 'X' },
        { alfa: 'Y' },
        { alfa: 'Z' },
      ]
    };
  },
  metaInfo() {
    return {
      title: "Business Director",
      keywords: "business",
      meta: [
        { name: 'description', content: "business" },
      ]
    }
  },
  created: function () {
    this.fetchItems();
  },
  methods: {
    // onKeyup(){
    //   // alert(this.searchQuery);
    //   this.fetchItems();
    // },
    submit: function () {
      console.log(this.searchQuery);
      this.fetchItems();
      // this.$router.push("/search?search=" + this.name);
    },
    handleSelectItem(al) {
      this.selectedItem = al;
      this.search = this.selectedItem;
      // you can also handle toggle action here manually to open and close dropdown
    },
    fetchItems() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (this.searchQuery == null) {
        db.collection("partner")
          .where("type", "==", 1)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // db.collection('companies').doc(doc.data().title)
              //   .get().then(snapshot => {
                  this.company_name = doc.data().title;
                  // console.log(snapshot.data().name)

                  this.partner.push({
                    id: doc.id,
                    title: this.company_name,
                    about: doc.data().about,
                    slugurl: doc.data().slugurl,
                    // slugurl: this.company_name.replace(/\s+/g, '-').toLowerCase(),
                    companylogo: doc.data().company_image,
                    companyheroimage: doc.data().company_hero_image,
                  });
                });
            // });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          }); console.log(this.partner);
      }else{
        this.partner = [];
        // console.log(this.searchQuery)
        // alert();
        db.collection("partner")
          .where("type", "==", 1)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              db.collection('companies').doc(doc.data().title)
                .get().then(snapshot => {
                  this.company_name = snapshot.data().name;
                  console.log(doc.data().country)
                  this.country_name = doc.data().country_name.toLowerCase()
                  if (this.country_name.match(this.searchQuery.toLowerCase())){
                    // console.log('test');
                  // if(snapshot.data().name == this.searchQuery || doc.data().country == this.searchQuery){
                    this.partner.push({
                      id: doc.id,
                      title: this.company_name,
                      about: doc.data().about,
                      slugurl: doc.data().slugurl,
                      // slugurl: this.company_name.replace(/\s+/g, '-').toLowerCase(),
                      companylogo: doc.data().company_image,
                      companyheroimage: doc.data().company_hero_image,
                    });
                  }
                });
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          }); console.log(this.partner);
      }
    },
  },
  computed: {
    displayedPosts() {

      return this.partner.filter(post => {
        //alert(post.title);
        return post.title.charAt(0).toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
};
</script>
<style>
@media (min-width: 768px) {
  .site-wrapper {
    border-bottom: none;
    border-radius: 165px 165px 0 0;
  }
}

/* new css 13-1-2022 */
.patners-listing-page {
  padding: 100px 0;
  min-height: 100vh;
}

.patners-listing-page h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 45px;
  text-transform: uppercase;
}

.patners-listing-page .listing-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
}

.patners-listing-page .listing-wrapper .listing-item {
  position: relative;
  width: 100%;
  flex: 0 0 31.33%;
  max-width: 31.33%;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  background: #fff;
}

.patners-listing-page .listing-wrapper .listing-item .listing-item-disc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 50px;
  margin-top: 10px;
  color: #aca0b4;
}

.patners-listing-page .listing-wrapper .listing-item .listing-item-logo {
  min-height: 90px;
  max-height: 90px;
  max-width: 210px;
  min-width: 210px;
  object-fit: cover;
  margin: 10px auto;
  display: block;
}

.patners-listing-page .listing-wrapper .listing-item .bg_img {
  display: block;
  width: 100%;
  max-height: 190px;
  min-height: 190px;
  object-fit: cover;
  border-radius: 10px;
}

.patners-listing-page .listing-wrapper .listing-item .comany_logo {
  position: absolute;
  top: 20px;
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  border-radius: 50%;
  border: 5px solid #fff;
  right: 20px;
}

.patners-listing-page .company_details {
  position: absolute;
  left: 25px;
  max-width: 85%;
  bottom: 15px;
}

.patners-listing-page .listing-item-name {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 20px;
  color: #555c5f;
}

.patners-listing-page .company_details .company_disc {
  font-size: 12.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 3px;
  color: #fff;
  line-height: 1.3;
}

.patners-listing-page .cta {
  background: #03e1bc;
  padding: 5px 15px;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}

.patners-listing-page .listing-wrapper .listing-item a i {
  margin-left: 6px;
  font-size: 13px;
}

.patners-listing-page .company_name {
  font-size: 18px;
  margin-bottom: 0;
  color: #fff;
}

.patners-listing-page .listing-wrapper .listing-item .bg_wrapper {
  position: relative;
}

.patners-listing-page .listing-wrapper .listing-item .bg_wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 10px;
  opacity: 0.5;
}

.patners-listing-page .pagination {
  justify-content: flex-end;
  margin-right: 10px;
}

.patners-listing-page .page-item.active .page-link {
  background-color: #03e1bc;
  border-color: #03e1bc;
}

.patners-listing-page .page-link {
  color: #03e1bc;
}

.patners-listing-page .page-link:focus,
.patners-listing-page .page-link:focus-visible {
  outline: none;
  box-shadow: none;
}

.patners-listing-page .pagination .page-item {
  display: flex;
}

.patners-listing-page .listing-wrapper .listing-item a {
  display: block;
  margin-bottom: 0;
  text-align: right;
  color: #03e1bc;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  bottom: 15px;
  right: 30px;
}

.patners-listing-page .listing-wrapper .listing-item a:hover {
  color: #03e1bc;
}

@media (max-width: 1024px) {
  .patners-listing-page .listing-wrapper .listing-item a {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .patners-listing-page .listing-wrapper .listing-item {
    flex: 0 0 30.33%;
    max-width: 30.33%;
  }
}

@media (max-width:768px) {
  .patners-listing-page .listing-wrapper .listing-item {
    flex: 0 0 47%;
    max-width: 47%;
  }
}

@media (max-width: 767px) {
  .patners-listing-page .listing-wrapper .listing-item a {
    flex: 0 0 100%;
    max-width: 100%;
    font-size: 14px;
    text-align: left;
  }

  .patners-listing-page h1 {
    margin-bottom: 10px;
    font-size: 25px;
    text-align: left;
    padding: 0;
  }

  .patners-listing-page {
    padding: 150px 0;
  }

  .patners-listing-page .pagination {
    justify-content: center;
  }

  .patners-listing-page .listing-wrapper .listing-item {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 20px 0;
  }
}

.AlphabetNav {
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-flow: wrap row;
  padding: 0.5rem;
}

.AlphabetNav a {
  display: flex;
  justify-content: center;
  padding: 0.5em;
  border-radius: 8px;
  font-size: 1.2em;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  color: darkgray;
  cursor: pointer;
}

.AlphabetNav a:hover {
  background: #ddd;
  color: #444;
}

.AlphabetNav .active {
  background: #f46353;
  color: white;
}
</style>
